import ai from '../images/portraits/ai.png';
import baseball from '../images/portraits/baseball.png';
import brewmeister from '../images/portraits/brewmeister.png';
import chaff from '../images/portraits/chaff.png';
import elevators1 from '../images/portraits/elevators1.png';
import elevators2 from '../images/portraits/elevators2.png';
import emccave from '../images/portraits/emccave.png';
import garage from '../images/portraits/garage.png';
import gym from '../images/portraits/gym.png';
import hugin from '../images/portraits/hugin.png';
import kitchen from '../images/portraits/kitchen.png';
import librixer from '../images/portraits/librixer.png';
import lobby1 from '../images/portraits/lobby1.png';
import lobby2 from '../images/portraits/lobby2.png';
import malmsteen from '../images/portraits/malmsteen.png';
import quotewall from '../images/portraits/quotewall.png';
import stairs from '../images/portraits/stairs.png';
import teal from '../images/portraits/teal.png';
import wolfgang from '../images/portraits/wolfgang.png';
import goodending from '../images/portraits/goodending.png';

export default {
  ai,
  baseball,
  brewmeister,
  chaff,
  elevators1,
  elevators2,
  emccave,
  garage,
  gym,
  hugin,
  kitchen,
  librixer,
  lobby1,
  lobby2,
  malmsteen,
  quotewall,
  stairs,
  teal,
  wolfgang,
  goodending
};
