import React from 'react';
import styled from 'styled-components';
import bpIcon from '../../images/backpack.png';

interface IBackpackButton {
  onClick: () => void;
}

const Container = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
`;
const Icon = styled.img`
  height: 70%;
`;

const BackpackButton: React.FC<IBackpackButton> = ({ onClick }) => (
  <Container onClick={onClick}>
    <Icon src={bpIcon} alt="backpack" />
  </Container>
);

export default BackpackButton;
