import styled, { css, keyframes } from 'styled-components';
import Breakpoints from '../../services/breakpoints';
import Divider from '../shared/Divider';

export const Container = styled.div`
  box-sizing: border-box;
  padding-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 50vh;
  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const ContainerLower = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  @media screen and (min-width: ${Breakpoints.MOBILE}) {
    align-items: center;
    padding: 0 24px;
  }
`;

export const ContainerCharacter = styled.div`
  display: flex;
  padding: 8px 0;
  box-sizing: border-box;
`;

export const ContainerCharacterContent = styled.div`
  margin-left: 16px;
`;

export const ContainerOutput = styled.div<{ isFlex: boolean }>`
  ${(props) =>
    props.isFlex &&
    css`
      flex: 1;
    `}
`;

export const TextCharacter = styled.p`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.secondaryLight};
`;

export const ImageCharacter = styled.img`
  width: 70px;
  height: 70px;
  @media screen and (min-width: ${Breakpoints.MOBILE}) {
    width: 120px;
    height: 120px;
  }
`;

export const ContainerImageSetting = styled.div`
  &:after {
    content: '';
    position: absolute;
    height: 20%;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.7) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  position: relative;
  width: 100%;
`;

export const ImageSetting = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;

export const DividerSecondary = styled(Divider)`
  background-color: grey;
`;

export const ContainerQuestion = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Prompt = styled.p`
  font-size: 1.1rem;
  text-align: center;
`;

const fadein = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const fadeout = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const Overlay = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  background-color: #000;
  z-index: 100;
  ${(props) =>
    props.show &&
    css`
      visibility: visible;
      opacity: 1;
      animation: ${fadein} 1.5s, ${fadeout} 1.5s 3s;
    `}
`;

export const TextOutput = styled.p`
  font-size: 1.2rem;
  @media screen and (min-width: ${Breakpoints.MOBILE}) {
    text-align: center;
    font-size: 1.5rem;
  }
`;

export const DialogueCharacter = styled.p`
  max-height: 180px;
  overflow-y: scroll;
`;

export const ContainerInnerUpper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerUpper = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
`;

export const ContainerInput = styled.div`
  @media screen and (min-width: ${Breakpoints.MOBILE}) {
    padding-bottom: 24px;
    width: 100%;
  }
`;
