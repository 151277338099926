import React, { useEffect, useState } from 'react';
import * as Styled from './style';
import Button from '../shared/Button';
import Spacer from '../shared/Spacer';
import BackpackButton from '../BackpackButton';
import { IRoom } from '../../models';
import { navigate } from 'gatsby-link';
import settings from '../../styles/settings';
import portraits from '../../styles/portraits';

interface RoomProps {
  room: IRoom;
  onInputClick: (roomId: string, path: number) => void;
  showOverlay: boolean;
}

const Room: React.FC<RoomProps> = ({ room, onInputClick, showOverlay }) => {
  const [hasReadInfo, setHasReadInfo] = useState<boolean>();
  useEffect(() => {
    setHasReadInfo(!room.output);
  }, [room]);
  return (
    <>
      <Styled.Container>
        <Styled.ContainerUpper>
          <BackpackButton onClick={() => navigate('/backpack')} />
          {room.setting && (
            <Styled.ContainerImageSetting>
              <Styled.ImageSetting src={settings[room.setting]} alt="setting" />
            </Styled.ContainerImageSetting>
          )}
        </Styled.ContainerUpper>
        <Styled.ContainerLower>
          <Styled.ContainerInnerUpper>
            {!!room.output && !hasReadInfo && (
              <>
                <Styled.ContainerOutput
                  isFlex={!room.character && !room.prompt}
                >
                  <Styled.TextOutput>{room.output}</Styled.TextOutput>
                </Styled.ContainerOutput>
                <Spacer spacing={2} />
              </>
            )}
            {hasReadInfo && (
              <>
                {room.character && (
                  <Styled.ContainerCharacter>
                    {room.character.image && (
                      <Styled.ImageCharacter
                        alt="character"
                        src={portraits[room.character.image]}
                      />
                    )}
                    <Styled.ContainerCharacterContent>
                      <Styled.TextCharacter>
                        {room.character.name}
                      </Styled.TextCharacter>
                      {room.character.dialogue && (
                        <Styled.DialogueCharacter>
                          {room.character.dialogue}
                        </Styled.DialogueCharacter>
                      )}
                    </Styled.ContainerCharacterContent>
                  </Styled.ContainerCharacter>
                )}
                {!!room.prompt && (
                  <>
                    {room.character && <Styled.DividerSecondary />}
                    <Styled.ContainerQuestion>
                      <Styled.Prompt>{room.prompt}</Styled.Prompt>
                    </Styled.ContainerQuestion>
                  </>
                )}
              </>
            )}
          </Styled.ContainerInnerUpper>
          {hasReadInfo ? (
            <Styled.ContainerInput>
              {room.inputs.map((input) => (
                <React.Fragment key={input.value}>
                  <Button
                    primary={input.isPrimary}
                    onClick={() => {
                      onInputClick(input.nextRoomId, input.audioPath);
                    }}
                  >
                    {input.value}
                  </Button>
                  <Spacer />
                </React.Fragment>
              ))}
            </Styled.ContainerInput>
          ) : (
            <Styled.ContainerInput>
              <Button primary onClick={() => setHasReadInfo(true)}>
                Continue
              </Button>
            </Styled.ContainerInput>
          )}
        </Styled.ContainerLower>
      </Styled.Container>
      <Styled.Overlay show={showOverlay} />
    </>
  );
};

export default Room;
