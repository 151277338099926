import React, { useContext, useEffect, useState } from 'react';
import Room from '../components/Room';
import roomsJSON from '../data/index.json';
import { navigate } from 'gatsby';
import {
  getCurrentRoom,
  getName,
  getToken,
  removeCurrentRoom,
  saveCurrentRoom
} from '../services/localStorage';
import { authenticate, enterRoom } from '../api';
import { ErrorContext } from '../contexts/ErrorContext';
import { SocketContext } from '../contexts/SocketContext';
import { AudioContext } from '../contexts/AudioContext';
import { pushRoomEvent } from '../services/gtag';

const GamePage = () => {
  const { handleError } = useContext(ErrorContext);
  const { start } = useContext(SocketContext);
  const { play, stop } = useContext(AudioContext);
  const [currentRoom, setCurrentRoom] = useState(roomsJSON['1']);
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    const savedRoomId = getCurrentRoom();
    if (savedRoomId) {
      setCurrentRoom(roomsJSON[savedRoomId]);
    }
    pushRoomEvent(savedRoomId ?? '1');
  }, []);

  const initStart = async () => {
    if (!getToken()) {
      await authenticate();
      start();
    } else {
      start();
    }
  };

  useEffect(() => {
    initStart();
  }, []);

  const handleGoToRoom = async (nextRoomId: string) => {
    pushRoomEvent(nextRoomId);
    const res = await enterRoom(nextRoomId);
    if (!!res) {
      start();
      goToRoom(nextRoomId);
    } else {
      handleError('Something went wrong...');
    }
  };

  const handleInputClick = (nextRoomId: string, audioPath: number) => {
    play(audioPath);
    if (nextRoomId === 'good_end_positive') {
      removeCurrentRoom();
      stop();
      if (!!getName()) {
        navigate('/end');
      } else {
        navigate('/register', {
          state: {
            fromGame: true
          }
        });
      }
    } else if (nextRoomId) {
      handleGoToRoom(nextRoomId);
    } else {
      removeCurrentRoom();
      stop();
      navigate('/end');
    }
  };

  const goToRoom = (roomId: string) => {
    setShowOverlay(true);
    setTimeout(() => {
      const room = roomsJSON[roomId];
      setCurrentRoom(room);
      saveCurrentRoom(roomId);
    }, 1000);
    setTimeout(() => {
      setShowOverlay(false);
    }, 4500);
  };

  return (
    <>
      <Room
        room={currentRoom}
        onInputClick={handleInputClick}
        showOverlay={showOverlay}
      />
    </>
  );
};

export default GamePage;
